import React, { useState } from 'react';
import {
  Box,
  Tooltip,
  Divider,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { Product } from '../../../generated/graphql';
import Autocomplete from '@material-ui/lab/Autocomplete';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { FastField, FieldArray, FieldProps } from 'formik';
import { sumQuantityByField } from '../../../utils/helpers';
import { COLORS, LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const WarehouseShipmentFormStep1: React.FC<{
  values: any;
  setFieldValue: any;
  productsLoading: boolean;
  showProducts: number;
  setShowProducts: any;
  products: any;
  setValues: any;
  isForwarding: boolean | undefined;
}> = ({
  values,
  setFieldValue,
  productsLoading,
  showProducts,
  setShowProducts,
  products,
  setValues,
  isForwarding,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [trackNumbersString, setTrackNumbersString] = useState<string>('');

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const getTotalQuantity = (products: any) => {
    let countProducts = 0;
    products?.forEach((item: any) => (countProducts += item.quantity));
    return countProducts;
  };

  const getTrackNumbers = () => {
    const trackNumbersArray = trackNumbersString.split(/[ ,;]/).filter(Boolean);

    const uniqueTrackNumbers = Array.from(
      new Set([...values?.trackNumberItems, ...trackNumbersArray]),
    );
    setFieldValue('trackNumberItems', uniqueTrackNumbers);

    setTrackNumbersString('');
  };

  const handleDeleteTrackNumber = (item: string) => () => {
    setFieldValue(
      'trackNumberItems',
      values?.trackNumberItems?.filter((number: string) => number !== item),
    );
  };

  const filteredSelectedProducts = () => {
    const filteredProducts = products.filter(
      (product: Product) => !values?.productsSku?.includes(product.sku),
    );
    return filteredProducts;
  };

  const count = values?.trackNumberItems?.length as number;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '30px',
        }}
      >
        <InputLabel shrink={false} htmlFor='input-identifiers-type'>
          {isForwarding
            ? t('app.addOrdersToWarehouse')
            : t('app.addItemsToWarehouse')}
        </InputLabel>
        {isForwarding ? (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              gridGap={'24px'}
            >
              <div style={{ width: '100%' }}>
                <Input
                  disableUnderline
                  id={`input-quantity`}
                  defaultValue={trackNumbersString}
                  onChange={(e) => setTrackNumbersString(e.target.value)}
                  value={trackNumbersString}
                  style={{ width: '100%' }}
                />
              </div>

              <Button variant='contained' onClick={getTrackNumbers}>
                <AddIcon /> {t('app.add')}
              </Button>
            </Box>
            {values?.trackNumberItems?.length ? (
              <div>
                <Typography
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    color: COLORS.GREEN,
                  }}
                >
                  {i18n.resolvedLanguage === 'ru'
                    ? count % 10 === 1 && count % 100 !== 11
                      ? t('app.addedParcel', {
                          count: count,
                          interpolation: { escapeValue: false },
                        })
                      : count % 10 >= 2 &&
                        count % 10 <= 4 &&
                        (count % 100 < 10 || count % 100 >= 20)
                      ? t('app.addedParcelPlural', {
                          count: count,
                          interpolation: { escapeValue: false },
                        })
                      : t('app.addedParcelPlural2', {
                          count: count,
                          interpolation: { escapeValue: false },
                        })
                    : count === 1
                    ? t('app.addedParcel', {
                        count: count,
                        interpolation: { escapeValue: false },
                      })
                    : t('app.addedParcelPlural', {
                        count: count,
                        interpolation: { escapeValue: false },
                      })}
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  justifyContent={'center'}
                >
                  {values?.trackNumberItems?.map((item: string) => (
                    <span className={classes.trackNumberItem}>
                      {item}{' '}
                      <CloseIcon
                        onClick={handleDeleteTrackNumber(item)}
                        style={{
                          cursor: 'pointer',
                          width: '14px',
                          height: '14px',
                        }}
                      />
                    </span>
                  ))}
                </Box>
              </div>
            ) : null}
          </>
        ) : (
          <FieldArray
            name='products'
            render={(helpers) => (
              <div>
                <Button
                  className={classes.showButton}
                  startIcon={<AddIcon />}
                  variant='text'
                  disabled={productsLoading}
                  onClick={() => {
                    helpers.push({ id: 0, quantity: 0 });
                  }}
                >
                  {t('app.addItem')}
                </Button>
                {productsLoading ? (
                  <ShowLoadingText name={t('app.productLoad')} />
                ) : null}
                {values.products.map(
                  (productItem: any, productIndex: any) =>
                    productItem && (
                      <div key={productIndex}>
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space',
                            alignItems: 'space-between',
                            position: 'relative',
                            marginBottom: '24px',
                          }}
                        >
                          <RemoveCircleOutlineIcon
                            className={classes.removeCircleIcon}
                            onClick={(e) => {
                              e.preventDefault();
                              helpers.remove(productIndex);
                            }}
                          />
                          <Autocomplete
                            style={{ marginRight: '20px' }}
                            fullWidth
                            loadingText={`${t('app.upload')}...`}
                            closeText={t('app.close')}
                            openText={t('app.open')}
                            clearText={t('app.clear')}
                            noOptionsText={t('app.noItemsFound')}
                            defaultValue={products.find(
                              (item: Product) =>
                                +item.id === values.products[productIndex].id,
                            )}
                            //@ts-ignore
                            options={filteredSelectedProducts() as Product[]}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  label={t('app.selectItemFromList')}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'my_products',
                                  }}
                                />
                              </div>
                            )}
                            renderOption={(option) => {
                              return (
                                <>
                                  {'SKU:'}{' '}
                                  {option?.sku ? `[${option?.sku}]` : ''}{' '}
                                  {`(${t('app.availableT', {
                                    value:
                                      option?.productAvailabilityQuantity
                                        ?.availableQuantity,
                                  })}; ${t('app.reservedT', {
                                    value:
                                      option?.productAvailabilityQuantity
                                        ?.reservedQuantity,
                                  })}) - `}{' '}
                                  {option?.name}
                                </>
                              );
                            }}
                            getOptionLabel={(option) => {
                              return `SKU: ${
                                option?.sku ? '[' + option?.sku + ']' : ''
                              } (${t('app.availableT', {
                                value:
                                  option?.productAvailabilityQuantity
                                    ?.availableQuantity,
                              })}; ${t('app.reservedT', {
                                value:
                                  option?.productAvailabilityQuantity
                                    ?.reservedQuantity,
                              })}) - ${option?.name || ''}`;
                            }}
                            onChange={(e, newValue) => {
                              const id =
                                newValue?.id && +newValue?.id
                                  ? +newValue?.id
                                  : null;

                              setFieldValue(`products[${productIndex}].id`, id);
                              setFieldValue(
                                `productsSku[${productIndex}]`,
                                newValue.sku,
                              );
                              setFieldValue(
                                `productsToSumm[${productIndex}].price`,
                                newValue?.declaredValue,
                              );
                              setFieldValue(
                                `productsToSumm[${productIndex}].netWeight`,
                                newValue?.netWeight,
                              );
                            }}
                          />
                          <FastField
                            name={`products[${productIndex}].quantity`}
                          >
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => {
                              return (
                                <FormControl
                                  style={{ width: '195px' }}
                                  error={
                                    meta.touched && !!meta.error && value !== 0
                                  }
                                >
                                  <InputLabel
                                    shrink={false}
                                    htmlFor={`input-quantity`}
                                  >
                                    {t('app.quantity')}
                                  </InputLabel>
                                  <Input
                                    placeholder={t('app.pcs')}
                                    disableUnderline
                                    id={`input-quantity`}
                                    {...field}
                                    defaultValue={value}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `products[${productIndex}].quantity`,
                                        +e.target.value,
                                      );
                                      setFieldValue(
                                        `productsToSumm[${productIndex}].quantity`,
                                        +e.target.value,
                                      );
                                    }}
                                    value={value}
                                  />
                                  {meta.touched &&
                                    !!meta.error &&
                                    value !== 0 && (
                                      <FormHelperText>
                                        {meta.error}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              );
                            }}
                          </FastField>
                        </Box>
                      </div>
                    ),
                )}
              </div>
            )}
          />
        )}

        {values?.products && values?.products?.length !== 0 && (
          <>
            <div className={classes.parcelInfo}>
              <span>
                {t('app.totalItems')}:{' '}
                <b>{getTotalQuantity(values.products)}</b> {t('app.pcs')}
              </span>
              <Divider
                className={classes.parcelInfoDivider}
                orientation='vertical'
              />
              <Tooltip title={t('app.totalWeight')}>
                <span>
                  {t('app.totalWeight')}:
                  <b>
                    {' '}
                    {+sumQuantityByField(
                      values.productsToSumm,
                      'netWeight',
                      (val) => `${val}`,
                    )
                      ? (+sumQuantityByField(
                          values.productsToSumm,
                          'netWeight',
                          (val) => `${val}`,
                        )).toFixed(2)
                      : t('app.addItem')}
                  </b>{' '}
                  {t('app.kg')}
                </span>
              </Tooltip>
              <Divider
                className={classes.parcelInfoDivider}
                orientation='vertical'
              />

              <span>
                {t('app.totalCost')}:
                <b>
                  {' '}
                  {+sumQuantityByField(
                    values.productsToSumm,
                    'price',
                    (val) => `${val}`,
                  )
                    ? (+sumQuantityByField(
                        values.productsToSumm,
                        'price',
                        (val) => `${val}`,
                      )).toFixed(2)
                    : t('app.addItemAndQuantity')}
                </b>{' '}
                {CURRENT_APP_CURRENCY}
              </span>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export default WarehouseShipmentFormStep1;
