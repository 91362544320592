import React from 'react';

import DeliveryOptionsComponent from '../../../components/DeliveryOptionsComponent/DeliveryOptionsComponent';

const WarehouseShipmentFormStep5: React.FC<{
  values: any;
  setFieldValue: any;
}> = ({ values, setFieldValue }) => {
  return (
    <DeliveryOptionsComponent
      carrierCode={values.carrierCode}
      trackNumber={values.trackNumber}
      setFieldValue={setFieldValue}
    />
  );
};

export default WarehouseShipmentFormStep5;
