import React, { useEffect, useState } from 'react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import useStyles from './styles';
import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import WarehouseShipmentFormStep1 from './WarehouseShipmentFormStep1';
import WarehouseShipmentFormStep2 from './WarehouseShipmentFormStep2';
import WarehouseShipmentFormStep4 from './WarehouseShipmentFormStep4';
import {
  CargoPlace,
  useGetMyProductListQuery,
  Product,
  useGetContactsQuery,
  useGetCurrentUserQuery,
  User,
  useCreateWarehouseShipmentUseProductsMutation,
  useCreateWarehouseForwardingMutation,
} from '../../../generated/graphql';
import { useHistory, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import { debounce } from 'debounce';
import { DEBOUNCE } from '../../../utils/constants';
import { createWarehouseShipment } from './warehouseUtils/createWarehouseShipment/createWarehouseShipment';
import { warehouseShipmentSchemas } from '../../../utils/validationSchemes';
import { When } from 'react-if';
import WarehouseShipmentFormStep3 from './WarehouseShipmentFormStep3';
import { createInitValueWarehouse } from './warehouseUtils/warehouseInitValues';
import WarehouseShipmentFormStep5 from './WarehouseShipmentFormStep5';
import WarehouseShipmentCreatedSuccessfully from '../WarehouseShipmentCreatedSuccessfully/WarehouseShipmentCreatedSuccessfully';
import { TRoutes } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const SendingToWarehouseCreate: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const forwardingRoute = useRouteMatch(
    TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_CREATE,
  );
  const isForwarding = forwardingRoute?.isExact;
  const { t } = useTranslation();

  const [productsList, setProductsList] = useState<Product[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [savedWarehouseShipmentId, setSavedWarehouseShipmentId] = useState<
    string | null
  >(null);

  const {
    data: products,
    loading: productsLoading,
    error,
  } = useGetMyProductListQuery({
    fetchPolicy: 'network-only',
  });

  const { error: errorContacts, data: contactsData } = useGetContactsQuery();

  const { error: errorCurrentUser, data: currentUserData } =
    useGetCurrentUserQuery();

  useEffect(() => {
    if (!products?.getMyProductList?.rows) return;

    products?.getMyProductList?.rows &&
      setProductsList(products?.getMyProductList?.rows as Product[]);
  }, [products]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  errorContacts?.message &&
    enqueueSnackbar(errorContacts.message, { variant: 'error' });
  errorCurrentUser?.message &&
    enqueueSnackbar(errorCurrentUser.message, { variant: 'error' });

  const [activeStep, setActiveStep] = useState(0);
  const [showProducts, setShowProducts] = useState(0);

  const [createWarehouseForwardingMutation] =
    useCreateWarehouseForwardingMutation();

  const [createWarehouseShipmentUseProductsMutation] =
    useCreateWarehouseShipmentUseProductsMutation();

  const [updateView, setUpdateView] = useState(0);

  if (savedWarehouseShipmentId) {
    return (
      <WarehouseShipmentCreatedSuccessfully
        id={savedWarehouseShipmentId}
        user={currentUserData as User}
        isForwarding={isForwarding}
      />
    );
  }

  return (
    <>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {!isForwarding
          ? t('app.createDeliveryToWarehouse')
          : t('app.createForwardingToWarehouse')}
      </Typography>

      <Formik
        initialValues={createInitValueWarehouse({
          currentUserData,
          isForwarding,
        })}
        validationSchema={warehouseShipmentSchemas[activeStep]}
        onSubmit={(values, helpers) => {
          if (activeStep < 4) {
            setActiveStep((prevState) => prevState + 1);
            helpers.setSubmitting(false);
          } else {
            createWarehouseShipment({
              createWarehouseForwardingMutation,
              createWarehouseShipmentUseProductsMutation,
              values: values as unknown as any,
              enqueueSnackbar,
              history,
              setSavedWarehouseShipmentId,
              isForwarding,
            });
          }
        }}
      >
        {({ values, setFieldValue, setValues, errors }) => {
          const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
          return (
            <Form noValidate autoComplete='off'>
              <Stepper orientation='vertical' activeStep={activeStep}>
                <Step>
                  <StepLabel>{t('app.whatAreWeSending')}</StepLabel>
                  <StepContent>
                    <WarehouseShipmentFormStep1
                      values={values}
                      setFieldValue={setFieldValue}
                      products={productsList as Product[]}
                      productsLoading={productsLoading}
                      showProducts={showProducts}
                      setShowProducts={setShowProducts}
                      setValues={setValues}
                      isForwarding={isForwarding}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.packaging')}</StepLabel>
                  <StepContent>
                    <WarehouseShipmentFormStep2
                      key={updateView}
                      values={values}
                      setFieldValueDebounce={setFieldValueDebounce}
                      cargoPlaces={values?.cargoPlaces as CargoPlace[]}
                      setValues={setValues}
                      setUpdateView={setUpdateView}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.whereAreWeSendingFrom')}</StepLabel>
                  <StepContent>
                    <WarehouseShipmentFormStep3
                      values={values}
                      setFieldValue={setFieldValue}
                      setUpdateView={setUpdateView}
                      contactsData={contactsData}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.whereAreWeSendingTo')}</StepLabel>
                  <StepContent>
                    <WarehouseShipmentFormStep4
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.deliveryOptions')}</StepLabel>
                  <StepContent>
                    <WarehouseShipmentFormStep5
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </StepContent>
                </Step>
              </Stepper>

              <div className={classes.actions}>
                <When condition={Boolean(Object.values(errors).length)}>
                  <p className={classes.errorsMessage}>
                    {t('app.checkAllFields')}
                  </p>
                </When>
                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  variant='contained'
                  disabled={activeStep === 0}
                  onClick={() =>
                    setActiveStep((prevState) => {
                      return prevState - 1;
                    })
                  }
                  startIcon={<ArrowLeftIcon />}
                >
                  {t('app.back')}
                </Button>

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  variant='contained'
                  type='submit'
                  startIcon={
                    activeStep === 4 ? <SaveIcon /> : <ArrowRightIcon />
                  }
                  onClick={() => {
                    window.scrollTo(0, 100);
                  }}
                >
                  {activeStep === 4 ? t('app.send') : t('app.next')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SendingToWarehouseCreate;
