import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: 'grey',
    },
    infoWrapper: {
      display: 'flex',
    },
    carrierCodeIcon: {
      width: 20,
      maxHeight: 16,
      marginRight: 10,
    },
    carrierCodeSelectedItem: {
      display: 'flex',
      alignItems: 'center',
    },
    trackNumberItem: { fontSize: '1rem', lineHeight: '1.5' },
  });

export default makeStyles(styles);
