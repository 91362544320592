import { WarehouseShipmentStatus } from './../../../generated/graphql';

export const filterSelectItems = [
  { value: '', label: 'app.status' },
  { value: WarehouseShipmentStatus.Created, label: 'app.created' },
  {
    value: WarehouseShipmentStatus.Processing,
    label: 'app.receivingInProgress',
  },
  { value: WarehouseShipmentStatus.Accepted, label: 'app.received' },
];
