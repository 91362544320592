import React, { ChangeEvent, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ExtendedCreateWarehouseShipmentOrderWithoutBuyLabelInput } from '../../../interfaces';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import DeliveryOptionsComponent from '../../../components/DeliveryOptionsComponent/DeliveryOptionsComponent';
import { useFormikContext } from 'formik';

const ShipmentFromWarehouseFormStep4 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<ExtendedCreateWarehouseShipmentOrderWithoutBuyLabelInput>();
  const [labelName, setLabelName] = useState<string>('');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const uploadedLabelName = file.name.split('.')[0];
      setLabelName(uploadedLabelName);
      setFieldValue('fileLabel', file);
    }
  };

  const hadleDeleteLabel = () => {
    setFieldValue('fileLabel', 'null');
    setLabelName('');
  };

  const isLabelUpdated = !!values.fileLabel && !!labelName;

  return (
    <div className={classes.wrapper}>
      <Box display={'flex'} alignItems={'center'} mb={3} mt={3}>
        <label htmlFor='file-upload' style={{ marginRight: '5px' }}>
          <IconButton component='span' style={{ padding: 0 }}>
            <AttachFileIcon />
          </IconButton>
        </label>
        <Typography style={{ marginRight: 5 }}>
          {!isLabelUpdated ? t('app.attachLabel') : labelName}
        </Typography>
        {isLabelUpdated ? (
          <CloseIcon onClick={hadleDeleteLabel} className={classes.closeIcon} />
        ) : null}
        <input
          id='file-upload'
          type='file'
          style={{ display: 'none' }}
          accept='.pdf,.jpeg,.png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
          onChange={handleFileChange}
        />
      </Box>
      <DeliveryOptionsComponent
        carrierCode={values.carrierService as string}
        trackNumber={values.trackNumber as string}
        setFieldValue={setFieldValue}
        fieldCarrierName={'carrierService'}
      />
    </div>
  );
};

export default ShipmentFromWarehouseFormStep4;
