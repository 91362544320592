import { getInitialCargoPlace } from '../../../../utils/helpers';
import { GetCurrentUserQuery } from '../../../../generated/graphql';

type createInitValueType = {
  currentUserData: GetCurrentUserQuery | undefined;
  isForwarding: boolean | undefined;
};

export const createInitValueWarehouse = ({
  currentUserData,
  isForwarding,
}: createInitValueType) => {
  const currentUser = currentUserData?.currentUser;
  const initialValues = {
    ...(isForwarding ? { trackNumberItems: [] } : { products: [] }),
    cargoPlaces: [getInitialCargoPlace()],
    from: {
      address: currentUser?.address,
      address2: currentUser?.address2,
      address3: currentUser?.address3,
      city: currentUser?.city,
      company: currentUser?.company,
      country: currentUser?.country,
      email: currentUser?.email,
      eoriCode: currentUser?.eoriCode,
      id: currentUser?.id,
      //@ts-ignore
      innKppCode: currentUser?.innKppCode,
      name: currentUser?.name,
      phone: currentUser?.phone,
      //@ts-ignore
      remark: currentUser?.remark,
      state: currentUser?.state,
      //@ts-ignore
      type: currentUser?.type,
      zipCode: currentUser?.zipCode,
    },
    warehouseId: 0,
    carrierCobe: '',
    trackNumber: '',
  };

  return initialValues;
};
