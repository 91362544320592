import React from 'react';
import { Button, Divider, Typography } from '@material-ui/core';

import useStyles from './styles';
import {
  useGetShipmentBarcodeListQuery,
  useGetWarehouseShipmentInfoQuery,
  User,
} from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';

const WarehouseShipmentCreatedSuccessfully: React.FC<{
  id: string | null;
  user: User | null | undefined;
  isForwarding?: boolean;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useGetWarehouseShipmentInfoQuery({
    variables: {
      // @ts-ignore
      id: props.id,
    },
  });
  const { data: barcodeListData } = useGetShipmentBarcodeListQuery({
    variables: {
      // @ts-ignore
      id: props.id,
    },
  });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography className={classes.title} variant='h2'>
            {t('app.arrivalCreated', {
              value: data?.getWarehouseShipmentInfo?.barcode?.substring(
                0,
                data?.getWarehouseShipmentInfo?.barcode.length - 4,
              ),
            })}
          </Typography>
          <Divider />
          <p className={classes.content}>
            {t('app.warehouseArrivalSuccess')}. <br />
            {t('app.printDocuments')}
          </p>

          <div className={classes.buttonsWrapper}>
            <Button
              disabled={!props.id}
              className={classes.printButton}
              variant='contained'
            >
              <a
                className={classes.link}
                href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${data?.getWarehouseShipmentInfo?.barcode}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('app.barcode')}{' '}
                {data?.getWarehouseShipmentInfo?.barcode?.substring(
                  0,
                  data?.getWarehouseShipmentInfo?.barcode.length - 4,
                )}
              </a>
            </Button>
            {!props.isForwarding ? (
              <Button disabled={!props.id} variant='contained'>
                <a
                  className={classes.link}
                  href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${barcodeListData?.getShipmentBarcodeList}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('app.goodsBarcode')}
                </a>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseShipmentCreatedSuccessfully;
