import React, { useEffect } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  Tooltip,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FastField, Field, FieldProps } from 'formik';
import {
  WarehouseShipmentOrderDeliveryOptionsInput,
  useGetWarehouseShipmentOrderAdditionalOptionsQuery,
} from '../../../generated/graphql';
import {
  TFormikSetFieldValue,
  TFormikSetFieldValueDebounce,
} from '../../../interfaces';
import { GIFT_MESSAGE_LINK, GIFT_WRAP_LINK } from '../../../utils/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export interface IWrapTypes {
  cost: number;
  name: string;
}

const ShipmentFromWarehouseFormStep3: React.FC<{
  values: WarehouseShipmentOrderDeliveryOptionsInput;
  setFieldValue: TFormikSetFieldValue;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  isComment: boolean;
  setIsComment: React.Dispatch<React.SetStateAction<boolean>>;
  isMessage: boolean;
  setIsMessage: React.Dispatch<React.SetStateAction<boolean>>;
  isWithLabel?: boolean;
}> = ({
  values,
  setFieldValue,
  setFieldValueDebounce,
  isWithLabel,
  isMessage,
  setIsMessage,
  isComment,
  setIsComment,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useGetWarehouseShipmentOrderAdditionalOptionsQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (!isMessage) setFieldValue('message', null);
    //eslint-disable-next-line
  }, [isMessage]);

  const wrapTypes = data?.getWarehouseShipmentOrderAdditionalOptions?.wrappers;
  const giftWrapperCost =
    data?.getWarehouseShipmentOrderAdditionalOptions?.giftWrapperCost;
  const giftMessageCost =
    data?.getWarehouseShipmentOrderAdditionalOptions?.giftMessageCost;

  return (
    <>
      <div className={classes.wrapper}>
        <Field name='senderMark'>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl
              error={meta.touched && !!meta.error}
              style={{ maxWidth: 261 }}
            >
              <Grid container justify='space-between'>
                <InputLabel shrink={false} htmlFor='sender-mark'>
                  {t('app.senderMark')}
                </InputLabel>
                {hidden ? null : (
                  <Tooltip
                    style={{ padding: 0 }}
                    title={t('app.typicallyOrderNumberSpecified')}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Input
                disableUnderline
                id='sender-mark'
                {...field}
                defaultValue={value}
                onChange={(e) => setFieldValue('senderMark', e.target.value)}
              />
              {meta.touched && meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          )}
        </Field>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
        {/* additional comment */}
        <FormControlLabel
          className={classes.labelRow}
          control={
            <Checkbox
              className={classes.radio}
              color='primary'
              checked={isComment}
              onChange={(e) =>
                setIsComment((prevState) => {
                  if (prevState === true) setFieldValue('comment', '');
                  return !prevState;
                })
              }
            />
          }
          label={
            <Box display={'flex'} alignItems={'center'}>
              <span className={classes.labelRowText}>
                {t('app.warehouseComment')}
              </span>
              <Tooltip
                style={{ padding: 0 }}
                title={t('app.leaveImportantMessageForWarehouse')}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
        />
        {!!isComment && (
          <Box maxWidth={280} style={{ marginTop: '20px' }}>
            <FastField name='comment'>
              {({ field: { value }, meta }: FieldProps) => {
                return (
                  <FormControl error={!!(meta.touched && meta.error)}>
                    <Input
                      disableUnderline
                      id='comment'
                      defaultValue={value}
                      inputProps={{
                        type: 'text',
                        maxlength: '100',
                      }}
                      onChange={(e) =>
                        setFieldValueDebounce('comment', e.target.value)
                      }
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            </FastField>
          </Box>
        )}
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
        {/* additional wrap */}
        <Typography className={classes.contactFieldsTitle} variant='h3'>
          {t('app.packaging')}
        </Typography>
        <Box>
          {loading ? (
            <CircularProgress color='inherit' size={22} />
          ) : (
            wrapTypes?.map((type: IWrapTypes) => {
              return (
                <label key={type.name} className={classes.labelRow}>
                  <Radio
                    className={classes.radio}
                    value={type}
                    checked={values?.wrap === type.name}
                    color='primary'
                    onChange={() => {
                      setFieldValue('wrap', type.name);
                    }}
                  />
                  <span
                    className={classes.labelRowText}
                    style={{ fontSize: '16px' }}
                  >
                    {`${type.name} - ${type.cost} $`}
                  </span>
                </label>
              );
            })
          )}
        </Box>

        <Box mt={6} mb={6}>
          <Divider />
        </Box>
        {/* additional purchase from warehouse */}
        <Typography className={classes.contactFieldsTitle} variant='h3'>
          {t('app.additionalServices')}
        </Typography>
        {isWithLabel ? (
          <FastField name='additionalInsurance'>
            {() => (
              <FormControlLabel
                className={classes.labelRow}
                control={
                  <Checkbox
                    className={classes.radio}
                    onChange={(e) =>
                      setFieldValue('additionalInsurance', e.target.checked)
                    }
                    checked={!!values?.additionalInsurance}
                    color='primary'
                  />
                }
                label={
                  <>
                    <span className={classes.labelRowText}>
                      {t('app.insurance')}
                    </span>
                    <Tooltip
                      className={classes.tooltipIconButton}
                      placement='top'
                      title={t('app.serviceChargeable')}
                    >
                      <IconButton
                        onClick={() => {
                          window.open(
                            'https://picknpack.pro/manual/package-insurance/',
                            '_blank',
                          );
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            )}
          </FastField>
        ) : null}
        {!!values.additionalInsurance && (
          <Box maxWidth={280}>
            <FastField name='insuranceAmount'>
              {({ field: { value }, meta }: FieldProps) => {
                return (
                  <FormControl error={!!(meta.touched && meta.error)}>
                    <InputLabel
                      shrink={false}
                      htmlFor='insuranceAmount'
                      style={{ marginTop: '24px' }}
                    >
                      {t('app.specifyCost')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      id='insuranceAmount'
                      defaultValue={value}
                      startAdornment={
                        <InputAdornment
                          style={{ paddingLeft: '10px' }}
                          position='start'
                        >
                          $
                        </InputAdornment>
                      }
                      inputProps={{
                        type: 'number',
                        min: 0,
                        step: 10,
                      }}
                      onBlur={(e) =>
                        setFieldValue('insuranceAmount', e.target.value)
                      }
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            </FastField>
          </Box>
        )}
        {isWithLabel ? (
          <FastField name='signature'>
            {() => (
              <FormControlLabel
                className={classes.labelRow}
                style={{ marginTop: '20px' }}
                control={
                  <Checkbox
                    className={classes.radio}
                    color='primary'
                    checked={!!values.signature}
                    onChange={(e) =>
                      setFieldValue('signature', e.target.checked)
                    }
                  />
                }
                label={
                  <>
                    <span className={classes.labelRowText}>
                      {t('app.recipientSignature')}
                    </span>
                    <Tooltip
                      className={classes.tooltipIconButton}
                      placement='top'
                      title='Услуга платная'
                    >
                      <IconButton
                        onClick={() => {
                          window.open(
                            'https://picknpack.pro/manual/recipient-signature/',
                            '_blank',
                          );
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            )}
          </FastField>
        ) : null}
        {loading ? (
          <CircularProgress color='inherit' size={22} />
        ) : (
          <FormControlLabel
            className={classes.labelRow}
            style={{ marginTop: '20px' }}
            control={
              <Checkbox
                className={classes.radio}
                color='primary'
                checked={isMessage}
                onChange={(e) => setIsMessage((prevState) => !prevState)}
              />
            }
            label={
              <>
                <span className={classes.labelRowText}>
                  {`${t('app.giftMessage')} - ${giftMessageCost} $`}
                </span>
                <Tooltip
                  className={classes.tooltipIconButton}
                  placement='top'
                  title={t('app.moreAboutService')}
                >
                  <IconButton
                    onClick={() => {
                      window.open(GIFT_MESSAGE_LINK, '_blank');
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          />
        )}

        {!!isMessage && (
          <Box maxWidth={280} style={{ marginTop: '20px' }}>
            <FastField name='message'>
              {({ field: { value }, meta }: FieldProps) => {
                return (
                  <FormControl error={!!(meta.touched && meta.error)}>
                    <Input
                      disableUnderline
                      id='message'
                      defaultValue={value}
                      inputProps={{
                        type: 'text',
                        maxlength: '500',
                      }}
                      onBlur={(e) => setFieldValue('message', e.target.value)}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            </FastField>
          </Box>
        )}
        {loading ? (
          <CircularProgress color='inherit' size={22} />
        ) : (
          <FastField name='giftWrap'>
            {() => (
              <FormControlLabel
                className={classes.labelRow}
                style={{ marginTop: '20px' }}
                control={
                  <Checkbox
                    className={classes.radio}
                    color='primary'
                    checked={!!values?.giftWrap}
                    onChange={(e) =>
                      setFieldValue('giftWrap', e.target.checked)
                    }
                  />
                }
                label={
                  <>
                    <span className={classes.labelRowText}>
                      {`${t('app.giftPackaging')} - ${giftWrapperCost} $`}
                    </span>
                    <Tooltip
                      className={classes.tooltipIconButton}
                      placement='top'
                      title={t('app.moreAboutService')}
                    >
                      <IconButton
                        onClick={() => {
                          window.open(GIFT_WRAP_LINK, '_blank');
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            )}
          </FastField>
        )}
      </div>
    </>
  );
};

export default ShipmentFromWarehouseFormStep3;
