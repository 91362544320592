import React from 'react';
import { Button } from '@material-ui/core';
import VkIcon from '../assets/img/auth/vk-icon.svg';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    socialButton: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginBottom: 5,
        width: '100%',
      },
    },
  }),
);

export const VkontakteLoginButton: React.FC<{
  callback: (token: string) => void;
}> = (props) => {
  const classes = styles();
  const { t } = useTranslation();

  const domain = 'oauth.vk.com';
  const apiId = process.env.REACT_APP_VKONTAKTE_APP_ID;
  const scope = 'offline';
  const response_type = 'token';
  const version = '5.122';

  const click = () => {
    const vkWindow = window.open(
      `https://${domain}/authorize?client_id=${apiId}&display=mobile&redirect_uri=${window.origin}/api/social/vk&scope=${scope}&response_type=${response_type}&v=${version}`,
      'example',
      'width=600,height=400',
    );

    if (!vkWindow) return;

    // @ts-ignore
    window.callbackVkSuccessToken = (token: string) => {
      props.callback(token);
    };
  };

  return (
    <Button
      className={classes.socialButton}
      variant='outlined'
      onClick={click}
      endIcon={<img src={VkIcon} alt='vk icon' />}
    >
      {t('app.vk')}
    </Button>
  );
};

export default VkontakteLoginButton;
