import { Box, Input, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { TFormikSetFieldValue } from '../../interfaces';
import { deliveryOptions } from './options';

interface IDeliveryOptionsComponent {
  carrierCode: string;
  trackNumber: string;
  setFieldValue: TFormikSetFieldValue;
  fieldCarrierName?: string;
}

interface IPlaceholder {
  children: string;
}

const DeliveryOptionsComponent = ({
  carrierCode,
  trackNumber,
  setFieldValue,
  fieldCarrierName = 'carrierCode',
}: IDeliveryOptionsComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const Placeholder = ({ children }: IPlaceholder) => (
    <div className={classes.placeholder}>{children}</div>
  );

  return (
    <Box mb={5} className={classes.infoWrapper}>
      <Box mb={2.5} mr={5}>
        <Select
          renderValue={
            carrierCode
              ? undefined
              : () => <Placeholder>{t('app.selectCarrier')}</Placeholder>
          }
          onChange={(e) => setFieldValue(fieldCarrierName, e.target.value)}
          value={carrierCode}
          disableUnderline
          displayEmpty
        >
          {deliveryOptions.map(({ value, label, src }) => {
            if (!value) {
              return (
                <MenuItem value='' className={classes.placeholder} disabled />
              );
            }
            return (
              <MenuItem
                value={value}
                className={classes.carrierCodeSelectedItem}
              >
                <img
                  className={classes.carrierCodeIcon}
                  src={src}
                  alt={value}
                />
                {t(label as string)}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box mb={2.5}>
        <Input
          value={trackNumber}
          disableUnderline
          placeholder={t('app.enterTrackingNumber3')}
          id={'input-track-number'}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            e.persist();
            setFieldValue('trackNumber', e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};

export default DeliveryOptionsComponent;
