import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { AvailableWarehouseProductsItem } from '../../../generated/graphql';
import { FastField, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { IB2bShipmentsValues } from './B2bShipmentSendForm';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { B2B_SHIPMENTS_PRODUCTS_TABLE_CELLS_ARRAY } from '../../../utils/constants';

const B2bShipmentSendFormStep2 = ({
  products,
}: {
  products: AvailableWarehouseProductsItem[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IB2bShipmentsValues>();
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCost, setTotalCost] = useState('');

  const removeProductHandle = (id: string) => {
    const newArrayProducts = values.products.filter(
      (product) => product?.product?.id !== id,
    );
    setFieldValue('products', newArrayProducts);
  };

  const selectedProducts = values.products;

  const totalQuantity = selectedProducts.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);

  useEffect(() => {
    const totalWeight = selectedProducts.reduce((acc, product) => {
      const { quantity } = product;
      if (product.product?.actualDimensions) {
        return Number(product.product.actualDimensions.weight) * quantity + acc;
      }
      return (
        Number(product?.product?.declaredDimensions?.weight) * quantity + acc
      );
    }, 0);
    setTotalWeight(totalWeight);
    //eslint-disable-next-line
  }, [totalQuantity]);

  useEffect(() => {
    const totalVolume = selectedProducts.reduce((acc, product) => {
      const { quantity } = product;
      if (product.product?.actualDimensions) {
        const volume =
          Number(product.product.actualDimensions.width) *
          Number(product.product.actualDimensions.length) *
          Number(product.product.actualDimensions.height) *
          quantity *
          0.000001;
        return acc + volume;
      }
      const volume =
        Number(product?.product?.declaredDimensions?.width) *
        Number(product?.product?.declaredDimensions?.length) *
        Number(product?.product?.declaredDimensions?.height) *
        quantity *
        0.000001;
      return acc + volume;
    }, 0);

    setTotalVolume(totalVolume);
    //eslint-disable-next-line
  }, [totalQuantity]);

  useEffect(() => {
    const currencyTotals: Record<string, number> = {};
    selectedProducts.forEach((product) => {
      const { quantity } = product;
      const { declaredValue, currency } = product.product!;
      if (!currencyTotals[currency as string]) {
        currencyTotals[currency as string] = 0;
      }
      currencyTotals[currency as string] += declaredValue! * quantity;
    });

    const result = Object.entries(currencyTotals)
      .map(([currency, total]) => `${total} ${currency}`)
      .join(' + ');

    setTotalCost(result);
    //eslint-disable-next-line
  }, [totalQuantity]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Autocomplete
            fullWidth
            loadingText={`${t('app.upload')}...`}
            closeText={t('app.close')}
            openText={t('app.open')}
            clearText={t('app.clear')}
            noOptionsText={t('app.noItemsFound')}
            options={products}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField
                  label={t('app.selectProduct2')}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'my_products',
                  }}
                />
              </div>
            )}
            renderOption={(option) => {
              return (
                <>
                  {`ID :${option?.product?.barcodeId?.split('.')[0]} SKU: ${
                    option?.product?.sku ? `[${option?.product?.sku}]` : ''
                  } ${t('app.availableReserved', {
                    value: option?.availableQuantity,
                    reserved: option?.reservedQuantity,
                  })} ${option?.product?.name}`}
                </>
              );
            }}
            getOptionLabel={(option) => {
              console.log('option', option);
              return `ID dwdwwfw:${
                option?.product?.barcodeId?.split('.')[0]
              } SKU: ${
                option?.product?.sku ? '[' + option?.product?.sku + ']' : ''
              } ${t('app.availableReserved', {
                value: option?.availableQuantity,
                reserved: option?.reservedQuantity,
              })} - ${option?.product?.name || ''}`;
            }}
            onChange={(e, newValue) => {
              if (
                values.products.some(
                  (product) => product?.product?.id === newValue?.product?.id,
                )
              ) {
                return;
              }
              setFieldValue('products', [
                ...values.products,
                {
                  ...newValue,
                  productId: Number(newValue?.product?.id),
                  quantity: 1,
                },
              ]);
            }}
          />
        </Grid>
      </Grid>
      {selectedProducts.length ? (
        <TableContainer component={Paper} className={classes.noStylesTable}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                {B2B_SHIPMENTS_PRODUCTS_TABLE_CELLS_ARRAY.map((text, i) => (
                  <TableCell
                    key={text}
                    align={!i ? 'left' : i === 1 ? 'right' : 'right'}
                  >
                    {t(text)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((option, index) => (
                <TableRow>
                  <TableCell>{`ID: ${
                    option?.product?.barcodeId?.split('.')[0]
                  } SKU: ${
                    option?.product?.sku ? `[${option?.product?.sku}]` : ''
                  } ${t('app.availableProducts', {
                    value: option?.availableQuantity,
                  })} ${option?.product?.name}`}</TableCell>
                  <TableCell align='right'>
                    <FastField name={`products[${index}].quantity`}>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            style={{ width: '90px' }}
                            error={meta.touched && !!meta.error && value !== 0}
                          >
                            <Input
                              placeholder={t('app.pcs')}
                              disableUnderline
                              id={`input-quantity`}
                              {...field}
                              defaultValue={value}
                              onChange={(e) => {
                                setFieldValue(
                                  `products[${index}].quantity`,
                                  +e.target.value,
                                );
                              }}
                              value={value}
                              inputProps={{
                                type: 'number',
                                step: 1,
                                min: 1,
                              }}
                            />
                            {meta.touched && !!meta.error && (
                              <FormHelperText>{t(meta.error)}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      onClick={() => {
                        removeProductHandle(String(option?.product?.id));
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {!!selectedProducts.length && (
        <Box className={classes.productsInformation}>
          <Typography>{`${t(
            'app.totalQuantity',
          )}: ${totalQuantity}`}</Typography>
          <Typography>{`${t('app.totalWeight')}: ${totalWeight.toFixed(2)} ${t(
            'app.kg',
          )}`}</Typography>
          <Typography>{`${t('app.totalVolume')}: ${parseFloat(
            totalVolume.toFixed(6),
          )} ${t('app.m3')}`}</Typography>
          <Typography>{`${t('app.totalCost')}: ${totalCost}`}</Typography>
        </Box>
      )}
    </div>
  );
};

export default B2bShipmentSendFormStep2;
