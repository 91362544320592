import React, { useState } from 'react';
import { ListItem, Typography, Grid, Box } from '@material-ui/core';
import useStyles from './styles';
import { useParams } from 'react-router-dom';
import {
  ShipmentWarehouseProduct,
  useGetWarehouseShipmentOrderAdditionalOptionsQuery,
  useGetWarehouseShipmentOrderByIdQuery,
  Vatkey,
  Warehouse,
  Warehouse_Shipment_Order_Type,
  WarehouseShipmentOrder,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { sumQuantityByField } from '../../../utils/helpers';

import ShipmentDetailsContact from '../../../components/ShimpentDetails/ShimpentDetailsContact/ShimpentDetailsContact';
import {
  DELIVERY_STATUS_BY_ID,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import ShipmentDetailsWarehouse from '../../../components/ShimpentDetails/ShipmentDetailsWarehouse/ShipmentDetailsWarehouse';
import { useTranslation } from 'react-i18next';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import TrackTrackNumberComponent from '../../../components/TrackTrackNumberComponent/TrackTrackNumberComponent';
import TableList from '../../../components/TableList/TableList';
import { headCells } from './options';
import { IWrapTypes } from '../ShipmentFromWarehouseForm/ShipmentFromWarehouseFormStep3';

const createData = (tableRowData: ShipmentWarehouseProduct) => {
  const relatedProduct = tableRowData?.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  const currency = relatedProduct?.currency;

  return {
    id: relatedProduct?.id,
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    quantity: tableRowData?.quantity,
    weight: relatedProduct?.netWeight,
    price: tableRowData?.price,
    currency,
  };
};

const ShipmentFromWarehouseDetails = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const [warehouseShipmentOrder, setWarehouseShipmentOrder] =
    useState<WarehouseShipmentOrder | null>(null);

  const { data, loading: addOptionsLoading } =
    useGetWarehouseShipmentOrderAdditionalOptionsQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const { loading } = useGetWarehouseShipmentOrderByIdQuery({
    variables: {
      id,
    },
    onCompleted: (data) => {
      if (data) {
        setWarehouseShipmentOrder(
          data?.getWarehouseShipmentOrderById as WarehouseShipmentOrder,
        );
      }
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const getTotalQuantity = (arr: any) => {
    let count = 0;
    arr?.forEach((product: any) => (count += product?.quantity));
    return count;
  };

  const getWrapLabel = (wrap: string) => {
    const wrapper =
      data?.getWarehouseShipmentOrderAdditionalOptions?.wrappers.find(
        (wrapper: IWrapTypes) => wrapper?.name === wrap,
      );

    return `${wrapper?.name} - ${wrapper?.cost} $`;
  };

  const rows =
    warehouseShipmentOrder?.products?.map((tableRowData) =>
      createData(tableRowData as ShipmentWarehouseProduct),
    ) || [];

  const isWithLabel =
    warehouseShipmentOrder?.type === Warehouse_Shipment_Order_Type.WithBuyLabel;

  const formattedDate = new Date(
    warehouseShipmentOrder?.createdAt as string,
  ).toLocaleDateString();

  const orderNumber = warehouseShipmentOrder?.barcodeId?.split('.')[0];

  return (
    <RequestHandler loading={loading || addOptionsLoading}>
      <>
        <Box mb={4}></Box>
        <Typography variant='h2' align='center' className={classes.mainTitle}>
          {t('app.viewOrder')}
        </Typography>
        <ListItem>
          <Typography variant='h3'>
            {`${t('app.orderNumber')}: ${orderNumber}`}
          </Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.creationDate')} ${formattedDate}`}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.variant')}: ${
            warehouseShipmentOrder?.carrierCode
              ? warehouseShipmentOrder?.carrierCode.toUpperCase() + ' '
              : ''
          } ${isWithLabel ? warehouseShipmentOrder?.carrierService : ''}`}
        </ListItem>
        {warehouseShipmentOrder?.trackNumber &&
          warehouseShipmentOrder?.carrierService && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.trackingNumber')}: `}
              <TrackTrackNumberComponent
                carrierCode={String(warehouseShipmentOrder?.carrierCode)}
                trackNumber={warehouseShipmentOrder?.trackNumber}
              />
            </ListItem>
          )}
        <ListItem className={classes.itemInfo}>
          {`${t('app.deliveryStatus')}: ${t(
            DELIVERY_STATUS_BY_ID[
              Number(warehouseShipmentOrder?.deliveryStatus?.id)
            ],
          )}`}
        </ListItem>
        {warehouseShipmentOrder?.paymentStatus?.explain && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.paymentStatus')}: ${
              warehouseShipmentOrder?.paymentStatus?.explain
            }`}
          </ListItem>
        )}
        <Grid container spacing={0} alignItems='stretch'>
          <Grid item sm={6} xs={12}>
            <ShipmentDetailsWarehouse
              type='sender'
              contact={warehouseShipmentOrder?.sender as Warehouse}
            />
          </Grid>
          {isWithLabel ? (
            <Grid item sm={6} xs={12}>
              <ShipmentDetailsContact
                type='receiver'
                //@ts-ignore
                contact={warehouseShipmentOrder?.receiver}
              />
            </Grid>
          ) : null}
        </Grid>
        <ListItem>
          <Typography variant='h3'>{t('app.informationAboutOrder')}</Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.numberOfItems')}: ${getTotalQuantity(
            warehouseShipmentOrder?.products,
          )}`}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.itemCost2')}: ${sumQuantityByField(
            warehouseShipmentOrder?.products as any[],
            'price',
            (val) => val,
          )} ${warehouseShipmentOrder?.currency}`}
        </ListItem>
        {warehouseShipmentOrder?.trackNumber && isWithLabel && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.dimensionsAndWeightActual')}: ${
              warehouseShipmentOrder?.dimensions?.length
            } x ${warehouseShipmentOrder?.dimensions?.width} x ${
              warehouseShipmentOrder?.dimensions?.height
            } ${t('app.cm')}, ${warehouseShipmentOrder?.dimensions?.weight} ${t(
              'app.kg',
            )}`}
          </ListItem>
        )}
        {warehouseShipmentOrder?.declaredDimensions && isWithLabel && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.dimensionsAndWeightDeclared')}: ${
              warehouseShipmentOrder?.declaredDimensions?.length
            } x ${warehouseShipmentOrder?.declaredDimensions?.width} x ${
              warehouseShipmentOrder?.declaredDimensions?.height
            } ${t('app.cm')}, ${
              warehouseShipmentOrder?.declaredDimensions?.weight
            } ${t('app.kg')}`}
          </ListItem>
        )}
        {warehouseShipmentOrder?.vatKey !== Vatkey.Empty && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.taxNumber')}: ${warehouseShipmentOrder?.vatKey} ${
              warehouseShipmentOrder?.vatValue
            }`}
          </ListItem>
        )}
        {isWithLabel ? (
          <>
            {warehouseShipmentOrder?.additionalInsurance ? (
              <>
                <ListItem className={classes.itemInfo}>
                  {`${t('app.additionalInsurance')}: ${t('app.yes')}`}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {`${t('app.insuranceCost')}: ${
                    warehouseShipmentOrder?.insuranceAmount
                  } ${CURRENT_APP_CURRENCY}`}
                </ListItem>
              </>
            ) : null}
            {warehouseShipmentOrder?.signature ? (
              <ListItem className={classes.itemInfo}>
                {`${t('app.recipientSignature')}: ${t('app.yes')}`}
              </ListItem>
            ) : null}
          </>
        ) : null}
        {warehouseShipmentOrder?.comment && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.warehouseComment')}: ${warehouseShipmentOrder?.message}`}
          </ListItem>
        )}
        {warehouseShipmentOrder?.wrap && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.packaging')}: ${getWrapLabel(
              warehouseShipmentOrder?.wrap,
            )}`}
          </ListItem>
        )}
        {warehouseShipmentOrder?.message && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.giftMessage')}: ${warehouseShipmentOrder?.message}`}
          </ListItem>
        )}
        {warehouseShipmentOrder?.giftWrap ? (
          <ListItem className={classes.itemInfo}>
            {`${t('app.giftPackaging')}: ${t('app.yes')}`}
          </ListItem>
        ) : null}
        {warehouseShipmentOrder?.actualAmount && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.shipmentCost')}: ${
              warehouseShipmentOrder?.actualAmount
            } ${CURRENT_APP_CURRENCY}`}
          </ListItem>
        )}

        <Box pb={1} pt={1} pl={2}>
          <Typography variant='h6'>{t('app.listOfItems')}</Typography>
        </Box>

        {rows.length ? (
          <TableList
            headCells={headCells}
            rows={rows}
            currency={warehouseShipmentOrder?.currency}
          />
        ) : null}
      </>
    </RequestHandler>
  );
};

export default ShipmentFromWarehouseDetails;
