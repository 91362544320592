import { History } from 'history';
import { t } from 'i18next';

type createWarehouseShipmentType = {
  createWarehouseForwardingMutation: any;
  createWarehouseShipmentUseProductsMutation: any;
  values: any;
  enqueueSnackbar: any;
  history: History;
  setSavedWarehouseShipmentId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  isForwarding: boolean | undefined;
};

export const createWarehouseShipment = ({
  createWarehouseForwardingMutation,
  createWarehouseShipmentUseProductsMutation,
  values,
  enqueueSnackbar,
  history,
  setSavedWarehouseShipmentId,
  isForwarding,
}: createWarehouseShipmentType) => {
  let input = {
    from: {
      name: values.from.name,
      company: values.from.company,
      //@ts-ignore
      countryId: values.from.country.id,
      address: values.from.address,
      address2: values.from.address2,
      address3: values.from.address3,
      zipCode: values.from.zipCode,
      city: values.from.city,
      state: values.from.state,
      phone: values.from.phone,
      email: values.from.email,
      innKppCode: values.from.innKppCode,
      remark: values.from.remark,
      eoriCode: values.from.eoriCode,
    },
    //@ts-ignore
    packages: values.cargoPlaces,
    warehouseId: values.warehouseId,
    ...(!isForwarding && { products: values.products }),
    ...(isForwarding && { trackNumberItems: values.trackNumberItems }),

    carrierCode: values.carrierCode,
    trackNumber: values.trackNumber,
  };

  if (isForwarding) {
    createWarehouseForwardingMutation({
      variables: {
        input,
      },
    })
      .then((response: any) => {
        enqueueSnackbar(
          t('app.shipmentToWarehouseSuccess', {
            value:
              response?.data?.createWarehouseForwarding?.barcode?.substring(
                0,
                response?.data?.createWarehouseForwarding?.barcode.length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        setSavedWarehouseShipmentId(
          response?.data?.createWarehouseForwarding?.id,
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  } else {
    createWarehouseShipmentUseProductsMutation({
      variables: {
        input,
      },
    })
      .then((response: any) => {
        enqueueSnackbar(
          t('app.shipmentToWarehouseSuccess', {
            value:
              response?.data?.createWarehouseShipmentUseProducts?.barcode?.substring(
                0,
                response?.data?.createWarehouseShipmentUseProducts?.barcode
                  .length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        setSavedWarehouseShipmentId(
          response?.data?.createWarehouseShipmentUseProducts?.id,
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  }
};
