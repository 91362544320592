import React, { useEffect, useState } from 'react';
import dhl from '../assets/img/carriers/dhl.svg';
import ups from '../assets/img/carriers/ups.svg';
import fedex from '../assets/img/carriers/fedex.svg';
import usps from '../assets/img/carriers/usps.png';
import tnt from '../assets/img/carriers/TNT.png';
import boxberry from '../assets/img/carriers/boxberry.png';
import dpd from '../assets/img/carriers/dpd.png';
import postRussia from '../assets/img/carriers/post_russia.png';
import apcLogistics from '../assets/img/carriers/apc_logistics.png';
import CDEK from '../assets/img/carriers/CDEK.png';
import amazon from '../assets/img/carriers/amazon.png';
import easypost from '../assets/img/carriers/easypost.png';
import other from '../assets/img/carriers/other.png';
import pendingImg from '../assets/img/statusDelivery/pending.png';
import transitImg from '../assets/img/statusDelivery/transit.png';
import deliveredImg from '../assets/img/statusDelivery/delivered.png';
import undeliveredImg from '../assets/img/statusDelivery/undelivered.png';
import cancelImg from '../assets/img/statusDelivery/cancel_Icon_1.png';

import {
  Box,
  CircularProgress,
  ListItem,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import {
  getCountryFlagUrlByISO,
  getCountryISOByName,
  TRoutes,
} from './helpers';
import getStatusesList from '../GraphQL/queries/getStatusesList';
import { useApolloClient } from '@apollo/client';
import {
  GetStatusesListQuery,
  GetStatusesListQueryVariables,
  Parcel,
  StatusDelivery,
  StatusPayment,
  WarehouseShipmentOrder,
} from '../generated/graphql';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { COLORS, colorsEnum } from './constants';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IActionsItem, ISidebarCollapseListItem } from './types';

const styles = {
  carrierCodeIcon: {
    maxWidth: 45,
    maxHeight: 25,
    marginRight: 5,
    marginLeft: 5,
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
  showLoadingText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
};

export const ShowLoadingText: React.FC<{ name?: string }> = ({ name }) => (
  <span style={{ ...styles.showLoadingText }}>
    <CircularProgress size={10} />
    &nbsp;{t('app.loading')}
    {name ? ` ${name}` : ''}...
  </span>
);

export const ConvertStatusIdToText: React.FC<{
  type: 'StatusDelivery' | 'StatusPayment';
  parcel: Parcel | WarehouseShipmentOrder;
  defaultText?: string;
}> = ({ type, parcel, defaultText }) => {
  const client = useApolloClient();
  const statuses = client.readQuery<
    GetStatusesListQuery,
    GetStatusesListQueryVariables
  >({
    query: getStatusesList,
  });

  const { t } = useTranslation();

  const [statusText, setStatusText] = useState('');
  const [tooltipText, setTooltipText] = useState('');
  const [link, setLink] = useState<null | string>(null);
  const [statusColor, setStatusColor] = useState('black');
  const [icon, setIcon] = useState<null | string>(null);

  const statusesDelivery =
    statuses &&
    statuses.getStatusesList &&
    statuses.getStatusesList.DeliveryStatusList
      ? (statuses.getStatusesList.DeliveryStatusList as StatusDelivery[])
      : undefined;
  const statusesPayment =
    statuses &&
    statuses.getStatusesList &&
    statuses.getStatusesList.PaymentStatusList
      ? (statuses.getStatusesList.PaymentStatusList as StatusPayment[])
      : undefined;

  useEffect(() => {
    if (type && parcel) {
      setLink(null);
      let status: StatusDelivery | StatusPayment | undefined = undefined;

      switch (parcel.__typename) {
        case 'Parcel':
          if (
            type === 'StatusDelivery' &&
            parcel.deliveryStatusId &&
            statusesDelivery &&
            statusesDelivery.length
          ) {
            status = statusesDelivery.find(
              (status) => status.id === parcel.deliveryStatusId,
            );
          }

          if (
            type === 'StatusPayment' &&
            parcel.paymentStatusId &&
            statusesPayment &&
            statusesPayment.length
          ) {
            status = statusesPayment.find(
              (status) => status.id === parcel.paymentStatusId,
            );
          }
          break;
        default:
          if (
            type === 'StatusDelivery' &&
            parcel?.deliveryStatus?.id &&
            statusesDelivery &&
            statusesDelivery.length
          ) {
            status = statusesDelivery.find(
              (status) => status.id === parcel?.deliveryStatus?.id,
            );
          }

          if (
            type === 'StatusPayment' &&
            parcel?.paymentStatus?.id &&
            statusesPayment &&
            statusesPayment.length
          ) {
            status = statusesPayment.find(
              (status) => status.id === parcel?.paymentStatus?.id,
            );
          }
      }

      if (status) {
        // Reset in case of status was changed (for example canceled)
        setLink(null);

        if (status.explain) {
          setStatusText(status.explain);
          setTooltipText(status.explain);
        }

        if (type === 'StatusPayment') {
          switch (status.name) {
            // Обрабатывается
            case 'pending': {
              if (parcel.declaredAmount) {
                const amount = parcel.actualAmount
                  ? parcel.actualAmount
                  : parcel.declaredAmount;
                const amountType = parcel.actualAmount
                  ? t('app.actual')
                  : t('app.declared');
                setTooltipText(
                  t('app.accountReplenishmentPage', {
                    amountType,
                    amount,
                  }),
                );
                setLink(`${TRoutes.ACCOUNT}/${amount}`);
                setStatusColor(COLORS.RED);
              }
              break;
            }

            // Оплачен
            case 'payment': {
              setTooltipText(t('app.paymentCompleted'));
              setStatusColor(COLORS.GREEN);
              break;
            }

            // Частичная оплата
            case 'partial payment': {
              let tooltip = '';
              if (parcel.actualAmount && parcel.declaredAmount) {
                const amount = parcel.actualAmount - parcel.declaredAmount;
                setLink(`${TRoutes.ACCOUNT}/${amount}`);
                tooltip = t('app.remainingToPay', {
                  value: `${parcel.actualAmount} - ${parcel.declaredAmount} = ${amount}`,
                });
              }
              setTooltipText(t('app.overweightDetected') + tooltip);
              setStatusColor(COLORS.ORANGE);
              break;
            }

            // Возврат
            case 'refund': {
              setTooltipText(t('app.paymentRefunded'));
              setStatusColor(COLORS.BLUE);
              break;
            }

            // Не требуется
            case 'no payment required': {
              setTooltipText(t('app.orderUnpaidCanceled'));
              setStatusColor(COLORS.GREY);
              break;
            }

            default: {
              setStatusColor('black');
              break;
            }
          }
        }

        if (type === 'StatusDelivery') {
          switch (status.name) {
            // Обрабатывается
            case 'pre_transit': {
              setTooltipText(t('app.orderCreated'));
              setIcon(pendingImg);
              break;
            }
            // В пути
            case 'transit': {
              setTooltipText(t('app.inTransit'));
              setIcon(transitImg);
              break;
            }
            // Выдан на доставку
            case 'pickup': {
              setTooltipText(t('app.cargoAccepted'));
              setIcon(transitImg);
              break;
            }
            // Доставлена
            case 'delivered': {
              setTooltipText(t('app.successfullyDelivered'));
              setStatusColor(COLORS.GREEN);
              setIcon(deliveredImg);
              break;
            }
            // Exception
            case 'exception': {
              setTooltipText(t('app.unusualDeliveryStatus'));
              setIcon(undeliveredImg);

              break;
            }
            // Отменен
            case 'cancel': {
              setTooltipText(t('app.orderCanceled'));
              setStatusColor('#808080');
              setIcon(cancelImg);
              break;
            }

            default: {
              setStatusColor('#000000');
              break;
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [statuses, parcel]);

  if (link) {
    return (
      <Tooltip title={tooltipText}>
        <Link to={link} style={{ color: statusColor }}>
          {statusText}
        </Link>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={tooltipText}>
      <span
        style={{
          color: statusColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon ? (
          <img src={icon} style={{ marginRight: '10px' }} alt='' />
        ) : null}
        {defaultText || statusText}
      </span>
    </Tooltip>
  );
};

export const GetCarrierCodeImage = ({
  carrierCode,
}: {
  carrierCode: string;
}) => {
  let imageSrc;
  switch (carrierCode) {
    case 'dhl': {
      imageSrc = dhl;
      break;
    }
    case 'dhlexpress': {
      imageSrc = dhl;
      break;
    }
    case 'ups': {
      imageSrc = ups;
      break;
    }
    case 'fedex': {
      imageSrc = fedex;
      break;
    }
    case 'usps': {
      imageSrc = usps;
      break;
    }
    case 'tnt': {
      imageSrc = tnt;
      break;
    }
    case 'boxberry': {
      imageSrc = boxberry;
      break;
    }
    case 'post_russia': {
      imageSrc = postRussia;
      break;
    }
    case 'dpd': {
      imageSrc = dpd;
      break;
    }
    case 'apc': {
      imageSrc = apcLogistics;
      break;
    }
    case 'cdek': {
      imageSrc = CDEK;
      break;
    }
    case 'amazon logistics': {
      imageSrc = amazon;
      break;
    }
    case 'easypost': {
      imageSrc = easypost;
      break;
    }
    case 'other': {
      imageSrc = other;
      break;
    }
    default: {
      imageSrc = undefined;
    }
  }
  return (
    <img
      style={{ ...styles.carrierCodeIcon }}
      src={imageSrc}
      alt={carrierCode}
    />
  );
};

export const renderCarrierCodeValue = (value: string) => {
  if (value === 'ups') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={ups} alt='ups' /> UPS
      </div>
    );
  } else if (value === 'dhl') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={dhl} alt='dhl' /> DHL
      </div>
    );
  } else if (value === 'usps') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={usps} alt='usps' /> USPS
      </div>
    );
  } else if (value === 'fedex') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={fedex} alt='fedex' />{' '}
        FEDEX
      </div>
    );
  } else if (value === 'boxberry') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img
          style={{ ...styles.carrierCodeIcon }}
          src={boxberry}
          alt='boxberry'
        />{' '}
        Boxberry
      </div>
    );
  } else if (value === 'post_russia') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img
          style={{ ...styles.carrierCodeIcon }}
          src={postRussia}
          alt='post_russia'
        />{' '}
        Почта России
      </div>
    );
  } else if (value === 'cdek') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={CDEK} alt='cdek' /> CDEK
      </div>
    );
  } else if (value === 'amazon logistics') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={amazon} alt='amazon' />{' '}
        Amazon logistics
      </div>
    );
  } else if (value === 'dpd') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={dpd} alt='dpd' /> DPD
      </div>
    );
  } else if (value === 'apc') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img
          style={{ ...styles.carrierCodeIcon }}
          src={apcLogistics}
          alt='apc_logistics'
        />{' '}
        APC Postal Logistics
      </div>
    );
  } else if (value === 'other') {
    return (
      <div style={{ ...styles.carrierCodeSelectedItem }}>
        <img style={{ ...styles.carrierCodeIcon }} src={other} alt='other' />{' '}
        Другой
      </div>
    );
  }
};

export const ConvertCdekStatus: React.FC<{ status: string }> = ({ status }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const iconStyle = {
    fontSize: 15,
  };

  const statuses = ['ACCEPTED', 'CANCELED', 'PENDING', 'REMOVED'];
  if (statuses.includes(status)) {
    switch (status) {
      case 'ACCEPTED':
        return (
          <Box style={style} color={COLORS.GREEN}>
            Принят <CheckIcon style={iconStyle} />
          </Box>
        );
      case 'CANCELED':
        return (
          <Box style={style} color={COLORS.RED}>
            Отменён <CancelIcon style={iconStyle} />
          </Box>
        );
      case 'PENDING':
        return (
          <Box style={style} color={COLORS.ORANGE}>
            Ожидание <HourglassEmptyIcon style={iconStyle} />
          </Box>
        );
      case 'REMOVED':
        return (
          <Box style={style} color={COLORS.RED}>
            Удаляется <DeleteForeverIcon style={iconStyle} />
          </Box>
        );
      default:
        return <Box style={style}>Неизвестно</Box>;
    }
  } else {
    return <Box style={style}>{status}</Box>;
  }
};

export const RenderCountryFlagImage: React.FC<{
  countryName: string;
  countryISO?: string;
  className?: string;
}> = ({ countryName, countryISO, className }) => {
  const style = {
    marginRight: 15,
    flexShrink: 0,
  };

  const ISO = countryISO || getCountryISOByName(countryName);

  return !!ISO ? (
    <div
      className={['PhoneInputCountryIcon', className].join(' ')}
      style={style}
    >
      <img
        className='PhoneInputCountryIconImg'
        src={getCountryFlagUrlByISO(ISO)}
        alt={countryName}
      />
    </div>
  ) : (
    <></>
  );
};

export const ConvertWarehouseStatus: React.FC<{ status: string }> = ({
  status,
}) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
  };

  const { t } = useTranslation();

  const statuses = ['CREATED', 'PROCESSING', 'ACCEPTED', 'CANCELED'];
  if (statuses.includes(status)) {
    switch (status) {
      case 'CREATED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.RED}>
            {t('app.created')}
          </Box>
        );
      case 'PROCESSING':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
            {t('app.receivingInProgress')}
          </Box>
        );
      case 'ACCEPTED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.GREEN}>
            {t('app.received')}
          </Box>
        );
      case 'CANCELED':
        return (
          <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.GREY}>
            {t('app.canceled')}
          </Box>
        );
      default:
        return <Box style={style}>{t('app.unknown')}</Box>;
    }
  } else {
    return <Box style={style}>{status}</Box>;
  }
};

export const ConvertB2bWarehouseStatusIdToColor: React.FC<{
  status: string;
}> = ({ status }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 7px',
  };

  const { t } = useTranslation();

  switch (status) {
    case 'COMPLETE':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.GREEN}>
          {t('app.completed')}
        </Box>
      );
    case 'CREATED':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.RED}>
          {t('app.created')}
        </Box>
      );
    case 'PICKED':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
          {t('app.picked')}
        </Box>
      );
    case 'BARCODE_REQURIED':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
          {t('app.barcodeRequired')}
        </Box>
      );
    case 'SHIPMENT_READY':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
          {t('app.shipmetnReady')}
        </Box>
      );
    case 'PROCESSING':
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.ORANGE}>
          {t('app.processingB2b')}
        </Box>
      );
    default:
      return (
        <Box style={style} color={COLORS.WHITE} bgcolor={COLORS.BLACK}>
          {t('app.cancelled')}
        </Box>
      );
  }
};

export const SidebarCollapseListItem = ({
  listITemClassName,
  navLinkClassName,
  activeClassName,
  onSelectItem,
  link,
  title,
}: ISidebarCollapseListItem) => {
  const { t } = useTranslation();

  return (
    <ListItem className={listITemClassName} button>
      <NavLink
        className={navLinkClassName}
        activeClassName={activeClassName}
        exact
        onClick={() => {
          if (onSelectItem) return onSelectItem();
        }}
        to={link}
      >
        {t(title)}
      </NavLink>
    </ListItem>
  );
};

export const ActionsItem = ({
  itemLinkName,
  linkClassName,
  iconClassName,
  redColorIconClassName,
  greenColorIconClassName,
  blueColorIconClassName,
  link,
  title,
  Icon,
  disabled = false,
  target = '_self',
  onClick = () => {},
  color = '',
  tooltipText,
  imgSrc,
}: IActionsItem) => {
  let iconClassNameWithColor = '';

  switch (color) {
    case colorsEnum.RED:
      iconClassNameWithColor = `${iconClassName} ${redColorIconClassName}`;
      break;
    case colorsEnum.GREEN:
      iconClassNameWithColor = `${iconClassName} ${greenColorIconClassName}`;
      break;
    case colorsEnum.BLUE:
      iconClassNameWithColor = `${iconClassName} ${blueColorIconClassName}`;
      break;
    default:
      iconClassNameWithColor = iconClassName as string;
      break;
  }

  return (
    <MenuItem
      disableGutters
      divider
      className={itemLinkName}
      disabled={disabled}
    >
      <Tooltip title={t(tooltipText as string)}>
        <Link
          className={linkClassName}
          to={link}
          target={target}
          onClick={onClick}
        >
          {!Icon ? (
            <img
              src={imgSrc}
              alt='barcode'
              className={iconClassName}
              style={{ width: 20, height: 20 }}
            />
          ) : (
            Icon && <Icon className={iconClassNameWithColor} />
          )}
          {t(title)}
        </Link>
      </Tooltip>
    </MenuItem>
  );
};
