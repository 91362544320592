import React, { ChangeEvent, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  InputAdornment,
  Radio,
  Typography,
} from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import {
  B2BShipmentMethodTypeEnum,
  CreateB2BOrderInput,
} from '../../../generated/graphql';
import { useFormikContext } from 'formik';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const B2bShipmentSendFormStep5 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateB2BOrderInput>();

  const [labelName, setLabelName] = useState<string>('');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const uploadedLabelName = file.name.split('.')[0];
      setLabelName(uploadedLabelName);
      setFieldValue('shipmentInfo.shipmentLabelFile', file);
    }
  };

  const hadleDeleteLabel = () => {
    setFieldValue('shipmentInfo.shipmentLabelFile', 'null');
    setLabelName('');
  };

  const handleCheckboxChange = (field: B2BShipmentMethodTypeEnum) => () => {
    setFieldValue('shipmentInfo.typeMethod', field);
  };

  const isLabelUpdated =
    !!values?.shipmentInfo?.shipmentLabelFile && !!labelName;

  return (
    <div>
      <Box display='flex' className={classes.pickerWrapper}>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.date')}
          </Typography>
          <DatePicker
            className={classes.datePicker}
            id='start_date'
            value={values.shipmentInfo?.scheduledDate}
            onChange={(date) => {
              if (date) {
                setFieldValue('shipmentInfo.scheduledDate', date);
              }
            }}
            format='DD/MM/yyyy'
            minDate={moment().toDate()}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.time')}
          </Typography>
          <TimePicker
            className={classes.datePicker}
            id='start_time'
            value={values.shipmentInfo?.scheduledTime}
            onChange={(time) => {
              if (time) {
                setFieldValue('shipmentInfo.scheduledTime', time);
              }
            }}
            format='HH:mm'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Box display='flex' alignItems='center'>
          <Radio
            className={classes.checkBox}
            value={B2BShipmentMethodTypeEnum.PicknPack}
            checked={
              values?.shipmentInfo?.typeMethod ===
              B2BShipmentMethodTypeEnum.PicknPack
            }
            color='primary'
            onChange={handleCheckboxChange(B2BShipmentMethodTypeEnum.PicknPack)}
          />
          <Typography>{t('app.pnpDelivery')}</Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Radio
            className={classes.checkBox}
            value={B2BShipmentMethodTypeEnum.Custom}
            checked={
              values?.shipmentInfo?.typeMethod ===
              B2BShipmentMethodTypeEnum.Custom
            }
            color='primary'
            onChange={handleCheckboxChange(B2BShipmentMethodTypeEnum.Custom)}
          />
          <Typography>{t('app.attachTransportLabel')}</Typography>
        </Box>
        {values?.shipmentInfo?.typeMethod ===
          B2BShipmentMethodTypeEnum.Custom && (
          <Box display={'flex'} alignItems={'center'} mt={3}>
            <label
              htmlFor='file-upload'
              style={{ marginRight: '5px', cursor: 'pointer' }}
            >
              <Box display='flex'>
                <IconButton component='span' style={{ padding: 0 }}>
                  <AttachFileIcon />
                </IconButton>
                <Typography style={{ marginLeft: 5 }}>
                  {!isLabelUpdated ? t('app.attachLabel') : labelName}
                </Typography>
              </Box>
            </label>
            {isLabelUpdated ? (
              <CloseIcon
                onClick={hadleDeleteLabel}
                className={classes.closeIcon}
              />
            ) : null}
            <input
              id='file-upload'
              type='file'
              style={{ display: 'none' }}
              accept='.doc],.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
              onChange={handleFileChange}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default B2bShipmentSendFormStep5;
