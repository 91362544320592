import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core';
import {
  Warehouse_Operations,
  useGetWarehousesListQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const SendingToWarehouseFormStep4: React.FC<{
  values: any;
  setFieldValue: any;
}> = ({ values, setFieldValue }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { data, error, loading } = useGetWarehousesListQuery({
    variables: { operation: Warehouse_Operations.ArrivalOrders },
  });
  const [warehouseList, setWarehouseList] = useState<any>([]);
  const [warehouse, setWarehouse] = useState<any>(null);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  useEffect(() => {
    data?.getWarehousesList?.rows &&
      setWarehouseList(data?.getWarehousesList?.rows);
  }, [data]);

  useEffect(() => {
    setFieldValue('warehouseId', warehouse?.id);
    // eslint-disable-next-line
  }, [warehouse]);

  return (
    <>
      <FormControl variant='standard'>
        <InputLabel htmlFor='warehouse'>{t('app.selectWarehouse2')}</InputLabel>
        <Select
          id='warehouse'
          disabled={loading}
          value={warehouse}
          onChange={(e) => {
            setWarehouse(e.target.value);
          }}
        >
          <MenuItem
            value={t('app.selectWarehouse2')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.selectWarehouse2')}
          </MenuItem>
          {warehouseList?.map((item: any) => (
            <MenuItem key={item.code} value={item}>
              {item.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {warehouse ? (
        <>
          <Typography style={{ marginTop: '20px' }}>
            {warehouse?.managerName}
          </Typography>
          <Typography>{warehouse?.company}</Typography>
          <Typography>{warehouse?.address}</Typography>
          <Typography>
            {warehouse.city} {warehouse.state}, {warehouse.zipCode}
          </Typography>
          <Typography>{warehouse.country?.nameEng}</Typography>
          <Typography>{warehouse?.phone}</Typography>
        </>
      ) : null}
    </>
  );
};

export default SendingToWarehouseFormStep4;
