import { History } from 'history';
import client from '../GraphQL/client';
import {
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  Maybe,
  OrderStatusesStatictic,
  Parcel,
  ParcelUnit,
  StatusDelivery,
  StatusPayment,
  ValidationAddressQuery,
  ValidationAddressQueryVariables,
} from '../generated/graphql';
import { QUERY_DELIVERY_COUNTRIES } from '../GraphQL/queries/getDeliveryCountries';
import {
  COLORS,
  CURRENCIES,
  LOCAL_STORAGE_KEYS,
  UNIT_STATES,
  doghuntStatusesEnums,
} from './constants';
import imgAmazon from '../assets/img/marketplaces/amazon.png';
import imgEtsy from '../assets/img/marketplaces/etsy.png';
import imgEbay from '../assets/img/marketplaces/ebay.png';
import React from 'react';
import {
  IGetInitialCargoPlace,
  IGetInitialPackage,
  IGetInitialUser,
  TSetStateArrayString,
} from '../interfaces';
import { QUERY_VALIDATE_ADDRESS } from '../GraphQL/queries/validationAddress';
import { TFunction, t } from 'i18next';
import moment from 'moment';

export class TRoutes {
  static MAIN = '/';
  static CONTACTS = '/contacts';
  static CONTACTS_CREATE = `${TRoutes.CONTACTS}/create`;
  static CONTACTS_DETAIL = `${TRoutes.CONTACTS}/:id`;

  static SHIPMENTS = '/shipments';
  static SHIPMENTS_FROM_WAREHOUSE = `${TRoutes.SHIPMENTS}`;
  static SHIPMENT_FROM_WAREHOUSE_DETAILS = `${TRoutes.SHIPMENTS}/fromwarehouse/details/:id`;
  static SHIPMENT_FROM_WAREHOUSE_DETAILS_WITHOUT_ID = `${TRoutes.SHIPMENTS}/fromwarehouse/details/`;
  static SHIPMENT_CREATE = `${TRoutes.SHIPMENTS}/create`;
  static SHIPMENT_FROM_WAREHOUSE_SELECT_TYPE_CREATE = `${TRoutes.SHIPMENTS}/fromwarehouse/select-type`;
  static SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL = `${TRoutes.SHIPMENTS}/fromwarehouse/create-with-label`;
  static SHIPMENT_FROM_WAREHOUSE_CREATE_WITHOUT_LABEL = `${TRoutes.SHIPMENTS}/fromwarehouse/create-without-label`;
  static SHIPMENT_EDIT = `${TRoutes.SHIPMENTS}/:id`;
  static SHIPMENT_EDIT_DRAFT = `${TRoutes.SHIPMENTS}/:id/draft`;
  static SHIPMENT_EDIT_COPY = `${TRoutes.SHIPMENTS}/:id/copy`;
  static SHIPMENT_DETAILS = `${TRoutes.SHIPMENTS}/:id/details`;

  static SELECT_TYPE_CALCULATOR = `/calculator`;
  static DELIVERY_CALCULATOR = `${TRoutes.SELECT_TYPE_CALCULATOR}/delivery-calc`;
  static FULFILMENT_CALCULATOR = `${TRoutes.SELECT_TYPE_CALCULATOR}/fulfilment-calc`;

  static RETURNS = '/returns';
  static PERSONAL = '/personal';
  static PERSONAl_VERIFY = '/personal-verify';
  static PERSONAl_DATA_VERIFY = '/personal-data-verify';

  static TRACK = '/track';
  static TRACK_WITH_NUMBER = `${TRoutes.TRACK}/:trackNumberParam`;
  static TRACK_WITH_NUMBER_AND_CARRIED_CODE = `${TRoutes.TRACK}/:carrierCodeParam/:trackNumberParam`;

  static REPORTS = '/reports';

  static ACCOUNT = '/account';
  static ACCOUNT_ID_AMOUNT = '/account/:parcelAmountParam';
  static ACCOUNT_PAYMENT_SUCCESSFUL = `${TRoutes.ACCOUNT}/payment/successful`;

  static AUTH = '/auth';
  static AUTH_SIGN_IN = `${TRoutes.AUTH}/sign-in`;
  static AUTH_SIGN_UP = `${TRoutes.AUTH}/sign-up`;
  static AUTH_CONFIRM = `${TRoutes.AUTH}/confirm`;
  static AUTH_ADD_EMAIL = `${TRoutes.AUTH}/add-email`;
  static AUTH_RESET = `${TRoutes.AUTH}/reset`;
  static AUTH_RECOVERY = `${TRoutes.AUTH}/recovery`;

  static WAREHOUSE = `/warehouse`;
  static WAREHOUSE_SEND_LIST = `${TRoutes.WAREHOUSE}/list`;
  static WAREHOUSE_SEND_DETAILS = `${TRoutes.WAREHOUSE}/details/:id`;
  static WAREHOUSE_SEND_CREATE = `${TRoutes.WAREHOUSE}/send/create`;
  static WAREHOUSE_SEND_EDIT = `${TRoutes.WAREHOUSE}/send/edit/:id`;

  static WAREHOUSE_SHIPMENT_LIST = `${TRoutes.WAREHOUSE}/shipment/list`;
  static WAREHOUSE_SHIPMENT_CREATE = `${TRoutes.WAREHOUSE}/shipment/create`;
  static WAREHOUSE_SHIPMENT_DETAILS = `${TRoutes.WAREHOUSE}/shipment/details/:id`;
  static WAREHOUSE_SHIPMENT_DETAILS_WITHOUT_ID = `${TRoutes.WAREHOUSE}/shipment/details/`;

  static WAREHOUSE_SHIPMENT_FORWARDING_LIST = `${TRoutes.WAREHOUSE}/shipment-forwarding/list`;
  static WAREHOUSE_SHIPMENT_FORWARDING_CREATE = `${TRoutes.WAREHOUSE}/shipment-forwarding/create`;

  static UNDECLARED_ARRIVAL_LIST = `${TRoutes.WAREHOUSE}/undeclared-arrival`;
  static UNDECLARED_ARRIVAL_DETAILS = `${TRoutes.WAREHOUSE}/undeclared-arrival/details/:id`;
  static UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID = `${TRoutes.WAREHOUSE}/undeclared-arrival/details/`;

  static RETURNS_RMA_LIST = `${TRoutes.RETURNS}/rma/list`;
  static RETURNS_RTS_LIST = `${TRoutes.RETURNS}/rts/list`;
  static RETURN_DETAILS = `${TRoutes.RETURNS}/details/:id`;
  static RETURN_DETAILS_WITHOUT_ID = `${TRoutes.RETURNS}/details/`;

  static NOT_FOUND = `/404`;
  static LEGAL_INFORMATION = `/legal-information`;
  static EULA = `/eula`;
  static REQUISITES = `/requisites`;

  static SETTINGS = '/settings';
  static SETTINGS_MY_PACKAGES = `${TRoutes.SETTINGS}/packages`;
  static SETTINGS_MY_PRODUCTS = `${TRoutes.SETTINGS}/products`;
  static SETTINGS_MY_PRODUCT_DETAILS = `${TRoutes.SETTINGS}/product/:id`;
  static SETTINGS_MY_PRODUCT_DETAILS_WITHOUT_ID = `${TRoutes.SETTINGS}/product/`;

  static SETTINGS_SENDER_ACCOUNTS = `${TRoutes.SETTINGS}/senders`;
  static SETTINGS_SENDER_ACCOUNTS_CREATE = `${TRoutes.SETTINGS_SENDER_ACCOUNTS}/create/:type`;
  static SETTINGS_SENDER_ACCOUNTS_UPDATE = `${TRoutes.SETTINGS_SENDER_ACCOUNTS}/update/:id`;

  // Orders fetched from remote marketplace (Amazon, eBay, Etsy, etc)
  static MARKETPLACE_ORDERS = `/orders`;

  // Client's marketplace accounts
  static MY_SHOPS = '/shops';
  static MY_SHOPS_CREATE = `${TRoutes.MY_SHOPS}/create/:type`;
  static MY_SHOPS_EDIT = `${TRoutes.MY_SHOPS}/edit/:id`;
  static MY_CARRIERS = '/carriers';

  static B2B_ORDERS = '/b2b';
  static B2B_ORDER_SEND = `${TRoutes.B2B_ORDERS}/send`;
  static B2B_ORDERS_DETAILS_WITHOUT_ID = `${this.B2B_ORDERS}/details/`;
  static B2B_ORDERS_DETAILS = `${this.B2B_ORDERS}/details/:id`;

  static NOTIFICATIONS = '/notifications';

  static ANALYTICS = '/analytics';
}

export class DELIVERY_VARIANTS {
  static FROM_WAREHOUSE_TO_DOOR = 0;
  static FROM_DOOR_TO_DOOR = 1;
}

export const USER_STATUS = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
};

export const DEPARTURE_DATE_FILTER = {
  ALL_TIME: 'all',
  LAST_7: 1,
  LAST_30: 2,
};

export const DASHBOARD_DEPARTURE_DATE_FILTER = {
  TODAY: 0,
  YESTERDAY: -1,
  LAST_7: 1,
  LAST_30: 2,
};

export const EXTENDED_DATE_FILTERS = {
  ALL_TIME: 0,
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7_DAYS: 3,
  LAST_30_DAYS: 4,
  RANGE: 5,
};

export const LIMIT_ITEM_PAGE = 20;
export const LIMIT_ITEM_UNREAD_NOTIFICATION = 10;

export const LIST_PROHIBITED_GOODS = [
  'Air Guns',
  'Animals, fish, birds (live)',
  'Bullion (of any precious metal)',
  'Cash (current legal tender)',
  'Cashlike negotiable instruments',
  'Dangerous goods, haz. or comb. mats',
  'Drugs(in pharmacy w/o prescription)',
  'Explosives and weapons',
  'Firearms (complete), ammunition',
  'Firearms, parts of',
  'Gambling device(incl. Playing card)',
  'Human remains, including ashes',
  'Hunting trophies,animal part/remain',
  'Illegal goods(counterfeit,narcotic)',
  'Imitation explos.devices,ammuniton',
  'Imitation(replica) firearms,weapons',
  'IVORY',
  'Loose (semi)precious stones',
  'PORNOGRAPHY',
  'PRECIOUS METALS & STONES',
];

export const getInitialUser: IGetInitialUser = (
  name,
  company,
  country,
  address,
  address2,
  address3,
  zipCode,
  city,
  state,
  phone,
  email,
  innKppCode,
  remark,
) => ({
  id: '',
  name: name || '',
  company: company || '',
  country: (country as any) || '',
  address: address || '',
  address2: address2 || '',
  address3: address3 || '',
  zipCode: zipCode || '',
  city: city || '',
  state: state || '',
  phone: phone || '',
  email: email || '',
  innKppCode: innKppCode || '',
  remark: remark || '',
});

export const getInitialUnit: () => ParcelUnit = () => ({
  id: '',
  nameRU: '',
  nameEN: '',
  tradeMark: '',
  code: null,
  quantity: 1,
  price: 0,
  country: {} as any,
  netWeight: null,
  state: UNIT_STATES.UNKNOWN,
  name: '',
  sku: '',
});

export const getInitialPackage: IGetInitialPackage = (
  weightKg,
  lengthCm,
  widthCm,
  heightCm,
) => ({
  id: '',
  weightKg: weightKg || 0,
  lengthCm: lengthCm || 0,
  widthCm: widthCm || 0,
  heightCm: heightCm || 0,
});

export const getInitialCargoPlace: IGetInitialCargoPlace = (
  weight,
  length,
  width,
  height,
) => ({
  weight: weight || 0,
  length: length || 0,
  width: width || 0,
  height: height || 0,
});

export const getInitialShipment: () => Parcel = () => ({
  id: '',
  currency: CURRENCIES.RUB.toLowerCase(),
  packages: [getInitialPackage()],
  senderNotes: '',
  typeDelivery: {
    //@ts-ignore
    id: '',
    name: '',
    serviceName: '',
  },
  additionalInsurance: false,
  declaredWeight: 0,
  declaredAmount: 0,
  sender: getInitialUser(),
  receiver: getInitialUser(),
});

export const sumByField = (
  arr: string | any[],
  name: string,
  format = (val: any) => val,
) => {
  let sum = 0;

  if (!arr.length) return format(sum);

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (isNaN(item[name])) return t('app.incorrectData');

    sum += Number(item[name]);
  }

  return format(sum);
};

export const sumQuantityByField = (
  arr: string | any[],
  nameField: string,
  format = (val: any) => val,
) => {
  let sum = 0;
  if (!arr?.length) return format(sum.toFixed(2));
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (isNaN(item[nameField]) || isNaN(item.quantity))
      return t('app.incorrectData');

    sum += Number(item[nameField]) * Number(item.quantity);
  }

  return format(sum.toFixed(2));
};

/**
 * Общий объёмный вес = (высота * длина * ширина) / 5000
 * @param arr
 * @param format
 * @returns {string|*}
 */
export const sumTotalVolumetricWeight = (
  arr: string | any[],
  format = (val: any) => val,
) => {
  let sum = 0;

  // No items in array - return zero
  if (!arr.length) return format(sum.toFixed(2));

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (
      isNaN(item['heightCm']) ||
      isNaN(item['widthCm']) ||
      isNaN(item['lengthCm'])
    )
      return t('app.incorrectData');

    const packageWeight =
      (+item['heightCm'] * +item['widthCm'] * +item['lengthCm']) / 5000;

    sum += packageWeight;
  }

  return format(sum.toFixed(2));
};

//@ts-ignore
export function logout(history: History<any> | string[]) {
  Object.values(LOCAL_STORAGE_KEYS).forEach((value) => {
    localStorage.removeItem(value as string);
  });

  client
    .resetStore()
    .catch((error) => console.error(error))
    .finally(() => history.push(TRoutes.AUTH_SIGN_IN));
}

const latinAlphabetAndNumbers = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];
export const checkAllowedCharacters = (characters: string) => {
  return characters
    .split('')
    .every((char) => latinAlphabetAndNumbers.includes(char));
};

export const getStatusIdByName = (
  statuses: Array<StatusDelivery | StatusPayment>,
  name: string,
) => {
  const status = statuses?.find((status) => status.name === name);
  if (status) {
    return status.id;
  }
};

export const getCDEKState = (state: string) => {
  switch (state) {
    case 'ACCEPTED':
      return 'Принят';
    case 'CREATED':
      return 'Создан';
    case 'RECEIVED_AT_SENDER_WAREHOUSE':
      return 'Принят на склад отправителя';
    case 'READY_FOR_SHIPMENT_IN_SENDER_CITY':
      return 'Выдан на отправку в городе отправителя';
    case 'RETURNED_TO_SENDER_CITY_WAREHOUSE':
      return 'Возвращен на склад отправителя';
    case 'TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY':
      return 'Сдан перевозчику в городе отправителя';
    case 'SENT_TO_TRANSIT_CITY':
      return 'Отправлен в город транзит';
    case 'ACCEPTED_IN_TRANSIT_CITY':
      return 'Встречен в городе транзите';
    case 'ACCEPTED_AT_TRANSIT_WAREHOUSE':
      return 'Принят на склад транзита';
    case 'RETURNED_TO_TRANSIT_WAREHOUSE':
      return 'Возвращен на склад транзита';
    case 'READY_FOR_SHIPMENT_IN_TRANSIT_CITY':
      return 'Выдан на отправку в городе транзите';
    case 'TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY':
      return 'Сдан перевозчику в городe транзите';
    case 'SENT_TO_RECIPIENT_CITY':
      return 'Отправлен в город получателя';
    case 'ARRIVED_AT_RECIPIENT_CITY':
      return 'Встречен в городе получателя';
    case 'ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE':
      return 'Принят на склад доставки';
    case 'ACCEPTED_AT_PICK_UP_POINT':
      return 'Принят на склад до востребования';
    case 'TAKEN_BY_COURIER':
      return 'Выдан на доставку';
    case 'RETURNED_TO_RECIPIENT_CITY_WAREHOUSE':
      return 'Возвращен на склад доставки';
    case 'DELIVERED':
      return 'Вручен';
    case 'NOT_DELIVERED':
      return 'Не вручен';
    case 'INVALID':
      return 'Некорректный заказ';
    case 'SELFEXPORT':
      return 'Самостоятельно';
    default:
      return 'Неизвестно';
  }
};

export const getWarehouseTafiffNameByCode = (code: number) => {
  switch (code) {
    case 136:
      return 'Посылка склад-склад';
    case 138:
      return 'Посылка дверь-склад';
    case 62:
      return 'Магистральный экспресс склад-склад';
    case 63:
      return 'Магистральный супер-экспресс склад-склад';
    case 999:
      return 'Самостоятельно';
  }
};

export const getCountryISOByName = (name: string) => {
  if (
    name === 'Россия' ||
    name === 'Russia' ||
    name === 'Российская Федерация' ||
    name === 'Russian Federation'
  ) {
    return 'RU';
  }
  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });
  return dataCountries?.deliveryCountries?.find((country) => {
    return country && (country.name === name || country.nameEng === name);
  })?.iso;
};

export const getCountryOrderByName = (name: string) => {
  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });
  return dataCountries?.deliveryCountries?.find((country) => {
    return country && (country.name === name || country.nameEng === name);
  })?.order;
};

export const getCountryOrderByISO = (iso: string) => {
  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });
  return dataCountries?.deliveryCountries?.find((country) => {
    return country && country.iso === iso;
  })?.order;
};

export const getCountryFlagUrlByISO = (iso: string) => {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;
};

export const getCountryNameByISO = (iso: string) => {
  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });
  return (
    dataCountries?.deliveryCountries?.find((c) => {
      return c && c?.iso === iso;
    })?.nameEng || 'Unknown'
  );
};

export const getMarketplaceImageSrc = (
  marketplace: 'etsy' | 'amazon' | 'ebay',
) => {
  switch (marketplace) {
    case 'amazon':
      return imgAmazon;
    case 'ebay':
      return imgEbay;
    case 'etsy':
      return imgEtsy;
    default:
      return '';
  }
};

export const handleSelectAllCheckboxes = (
  e: React.ChangeEvent<HTMLInputElement>,
  setSelected: TSetStateArrayString,
  items: any[],
) => {
  if (e.target.checked) {
    setSelected(items.map((item) => item.id.toString()));
  } else {
    setSelected([]);
  }
};

export const handleSelectCheckboxItem = (
  e: React.ChangeEvent<HTMLInputElement>,
  itemId: string,
  selected: string[],
  setSelected: TSetStateArrayString,
) => {
  const selectedIndex = selected.indexOf(itemId);
  let newSelected: string[] = [];

  // Item is not found (not selected yet)
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, itemId);
    // Item is already the first element in the selected array
  } else if (selectedIndex === 0) {
    // Remove first element from selected
    newSelected = newSelected.concat(selected.slice(1));
    // Item is the last element in the selected array
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  setSelected(newSelected);
};

export const getRequiredMelissaTitle = (
  country: string | null | undefined,
  city: string | null | undefined,
  zipCode: string | null | undefined,
  address: string | null | undefined,
) => {
  return (
    `${t('app.fillFields')}: ` +
    `${!country ? t('app.country').toLowerCase() : ''} ` +
    `${!city ? t('app.city').toLowerCase() : ''} ` +
    `${!zipCode ? t('app.index').toLowerCase() : ''} ` +
    `${!address ? t('app.address').toLowerCase() : ''}`
  );
};

export const queryValidateAddressMelissa = (
  country: string,
  city: string,
  state: string,
  zipCode: string,
  address: string,
  countryIso: string,
) => {
  return client.query<ValidationAddressQuery, ValidationAddressQueryVariables>({
    query: QUERY_VALIDATE_ADDRESS,
    variables: {
      input: {
        city: city,
        state: state,
        address: address,
        countryName: country,
        postalCode: zipCode,
        countryIso: countryIso,
      },
    },
    fetchPolicy: 'network-only',
  });
};

export const getInfo = (info: any, priceCurrency: any) => {
  if (info.warehouse_delivery_cost) {
    return `${t('app.deliveryOption')}
      ${t(
        'app.deliveryToWarehouse',
      )} - ${info?.warehouse_delivery_cost?.toFixed(2)} ${priceCurrency}
   ${t(
     'app.processingAtWarehouse',
   )} - ${info?.warehouse_proccesing_cost?.toFixed(2)} ${priceCurrency}
    ${t('app.labelCost')} - ${info?.last_mile_label_cost?.toFixed(
      2,
    )} ${priceCurrency}
    `;
  } else {
    return `${t('app.labelAndProcessingCost')}
      ${t('app.processingCost')} - ${info?.warehouse_proccesing_cost?.toFixed(
      2,
    )} ${priceCurrency}
    ${t('app.labelCost')} - ${info?.last_mile_label_cost?.toFixed(
      2,
    )} ${priceCurrency}
    `;
  }
};

export const isListOpenLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue === 'true' || false;
};

export const getDoghuntData = (
  t: TFunction<'translation', undefined>,
  orderStatuses?: OrderStatusesStatictic[],
) => {
  const backgroundColors: string[] = [];
  const labels: string[] = [];
  orderStatuses?.forEach((order) => {
    switch (order.name) {
      case doghuntStatusesEnums.PENDING:
        backgroundColors.push(COLORS.DOGHUNT_GREY);
        labels.push(t('app.created'));
        break;
      case doghuntStatusesEnums.TRANSIT:
        backgroundColors.push(COLORS.DOGHUNT_YELLOW);
        labels.push(t('app.inTransit'));
        break;
      case doghuntStatusesEnums.DELIVERED:
        backgroundColors.push(COLORS.DOGHUNT_GREEN);
        labels.push(t('app.delivered'));
        break;
      case doghuntStatusesEnums.EXCEPTION:
        backgroundColors.push(COLORS.DOGHUNT_RED);
        labels.push(t('app.exclusion'));
        break;
      case doghuntStatusesEnums.CANCELED:
        backgroundColors.push(COLORS.DOGHUNT_BLACK);
        labels.push(t('app.canceled'));
        break;

      default:
        break;
    }
  });

  const data = {
    labels: labels,
    datasets: [
      {
        data: orderStatuses?.map((status) => status.count),
        backgroundColor: backgroundColors,
      },
    ],
  };
  return data;
};

export function getFormatDateWithDayByLocale(
  locale?: string,
  date?: Maybe<string>,
) {
  moment.locale(locale as string);
  return moment(date).format('D ddd');
}
